import React from 'react';
import { Button, Card } from 'react-bootstrap';
import './UserCard.css'; // Import custom CSS file for UserCard

const UserCard = ({ user, onEdit, onDelete }) => {
    return (
        <Card className="user-card">
            <Card.Img variant="top" src={user.profilePicture} alt={user.name} className="card-img" />
            <Card.Body>
                <Card.Title className="card-title">{user.name}</Card.Title>
                <div className="button-group">
                    <Button variant="primary" onClick={() => onEdit(user)} className="edit-btn">
                        View
                    </Button>
                    <Button variant="danger" onClick={() => onDelete(user.id)} className="delete-btn">
                        Delete
                    </Button>
                </div>
            </Card.Body>
        </Card>
    );
};

export default UserCard;
