import React from 'react';
import './Footer.css';
import { Button, Stack } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const Footer = () => {
    const navigate = useNavigate();

    const handleButtonClick = () => {
        navigate('UsersDetails');
    };
    return (
        <footer className="footer">
            <Stack direction="horizontal" className="footer-stack">
                <p className="footer-text">&copy; TMK. All rights reserved.</p>
                <Button variant="warning" style={{marginRight:50}} onClick={handleButtonClick}>உறுப்பினர் விவரங்கள்</Button>{' '}
            </Stack>
        </footer>
    );
};

export default Footer;
