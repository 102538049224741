import React from 'react';
import './Header.css';
import headerimage from '../../assets/images/tmk.png';
import flag from '../../assets/images/flag.png';

const Header = () => {
    return (
        <div className='header'>
            <img src={headerimage} alt="Header" />
            <img src={flag} alt="Flag" />
        </div>
    );
};

export default Header;
