import React, { useState, useEffect } from 'react';
import Header from '../components/Header/Header';
import UserCard from '../components/UserCard';
import { remove, ref, update, get } from 'firebase/database';
import { Form, Modal, Button, InputGroup, Spinner } from 'react-bootstrap';
import { getDatabase, onValue } from 'firebase/database';
import { getStorage, ref as storageRef, deleteObject } from 'firebase/storage';
import './Users.css'; // Import the CSS file

const Users = () => {
    const [users, setUsers] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedUser, setSelectedUser] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [updatedUser, setUpdatedUser] = useState({});
    const [loading, setLoading] = useState(true); // Add loading state

    useEffect(() => {
        const db = getDatabase();
        const usersRef = ref(db, 'users');
        const handleData = (snapshot) => {
            const data = snapshot.val();
            if (data) {
                const usersList = Object.keys(data).map(key => ({
                    id: key,
                    ...data[key]
                }));
                setUsers(usersList);
            } else {
                setUsers([]);
            }
            setLoading(false); // Set loading to false when data is fetched
        };

        const unsubscribe = onValue(usersRef, handleData);

        // Cleanup subscription on component unmount
        return () => unsubscribe();
    }, []);

    const handleEdit = (user) => {
        setSelectedUser(user);
        setUpdatedUser({ ...user });
        setShowModal(true);
    };

    const handleDelete = async (userId) => {
        const db = getDatabase();
        const userRef = ref(db, `users/${userId}`);
        
        // Get user data to retrieve image URL
        const snapshot = await get(userRef);
        const userData = snapshot.val();
        
        if (userData && userData.profilePicture) {
            // Initialize Firebase Storage
            const storage = getStorage();
            const imageRef = storageRef(storage, userData.profilePicture);

            // Delete image from Firebase Storage
            try {
                await deleteObject(imageRef);
                console.log('Image deleted successfully');
            } catch (error) {
                console.error('Error deleting image:', error);
            }
        }

        // Remove user record from Firebase Realtime Database
        try {
            await remove(userRef);
            console.log('User deleted successfully');
        } catch (error) {
            console.error('Error deleting user:', error);
        }
    };

    const handleUpdate = async () => {
        const db = getDatabase();
        const userRef = ref(db, `users/${updatedUser.id}`);
        await update(userRef, updatedUser);
        setShowModal(false);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setUpdatedUser(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const filteredUsers = users.filter(user => 
        user.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <div>
            <Header />
            <div className="users-container">
                <InputGroup className="mb-3">
                    <Form.Control
                        type="text"
                        placeholder="Search by name"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        className="search-bar"
                    />
                </InputGroup>
                {loading ? (
                    <div className="text-center">
                        <Spinner animation="border" />
                    </div>
                ) : filteredUsers.length > 0 ? (
                    <div className="user-cards p-5">
                        {filteredUsers.map(user => (
                            <UserCard
                                key={user.id}
                                user={user}
                                onEdit={() => handleEdit(user)}
                                onDelete={() => handleDelete(user.id)}
                                className="user-card"
                            />
                        ))}
                    </div>
                ) : (
                    <div className="text-center">
                        <p>No result found</p>
                    </div>
                )}
            </div>

            {/* Edit Modal */}
            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit User</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {selectedUser && (
                        <Form>
                            <Form.Group className="mb-3">
                                <Form.Label>Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="name"
                                    readOnly
                                    value={updatedUser.name}
                                    onChange={handleChange}
                                />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Member ID</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="memberId"
                                    readOnly
                                    value={updatedUser.memberId}
                                    onChange={handleChange}
                                />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Father/Husband Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="fatherOrHusbandName"
                                    readOnly
                                    value={updatedUser.fatherOrHusbandName}
                                    onChange={handleChange}
                                />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Birth Date</Form.Label>
                                <Form.Control
                                    type="date"
                                    name="birthDate"
                                    readOnly
                                    value={updatedUser.birthDate}
                                    onChange={handleChange}
                                />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Contact Number</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="contactNumber"
                                    readOnly
                                    value={updatedUser.contactNumber}
                                    onChange={handleChange}
                                />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Serial Number</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="serialNumber"
                                    readOnly
                                    value={updatedUser.serialNumber}
                                    onChange={handleChange}
                                />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Blood Group</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="bloodGroup"
                                    readOnly
                                    value={updatedUser.bloodGroup}
                                    onChange={handleChange}
                                />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Address</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="address"
                                    readOnly
                                    value={updatedUser.address}
                                    onChange={handleChange}
                                />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Ward Number</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="address"
                                    readOnly
                                    value={updatedUser.wardNumber}
                                    onChange={handleChange}
                                />
                            </Form.Group>
                        </Form>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModal(false)}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleUpdate}>
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default Users;
