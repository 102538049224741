import React from 'react';
import { Page, Text, View, Document, StyleSheet, Image, Font } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';
import { pdf } from '@react-pdf/renderer';
import NotoSansTamil from '../../utils/Fonts/NotoSansTamil_Condensed-Black.ttf';
import logo from "../../assets/images/tmk.png";
import flag from "../../assets/images/flag.png";
import quote from "../../assets/images/quote.png";
import address from "../../assets/images/address.png";
import HeaderImg from "../../assets/images/header2.png";

// Register the custom font
Font.register({
  family: 'NotoSans',
  src: NotoSansTamil,
});

// Create styles with the custom font
const styles = StyleSheet.create({
  page: {
    width: 240,
    height: 150,
    border: '2px solid green',
    backgroundColor: '#ffffff',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: 5,
    marginBottom: 5,
    padding: 10,
  },
  logo: {
    width: 160,
    height: 18,
    objectFit: 'contain',
    bottom: 7,
  },
  quote: {
    width: 70,
    height: 10,
    objectFit: 'contain',
    position: 'absolute',
    top: 10,
  },
  address: {
    width: 170,
    height: 10,
    objectFit: 'contain',
    position: 'absolute',
    top: 18,
  },
  profileContainer: {
    flexDirection: 'column',
    height: 110,
  },
  profileImage: {
    height: '100%',
    width: '100%',
  },
  imageView: {
    width: 70,
    height: 90,
    borderColor: 'green',
    borderWidth: 1,
    borderRadius: 5,
    marginLeft: 5,
    overflow: 'hidden',
    marginTop: 10,
  },
  detailsContainer: {
    flexDirection: 'column',
    flexGrow: 1,
    justifyContent: 'space-around',
    marginLeft: 10,
    height: 85,
    padding: 5,
    marginTop: 10,
  },
  title: {
    fontSize: 14,
    fontFamily: 'NotoSans',
    fontWeight: 'bold',
    marginBottom: 5,
  },
  field: {
    fontSize: 8,
    fontFamily: 'NotoSans',
    marginBottom: 3,
    marginVertical: 5,
  },
  label: {
    fontWeight: 'bold',
    color: 'green',
    marginVertical: 6,
  },
  value: {
    marginLeft: 5,
    color: 'green',
    marginVertical: 6,
  },
  label2: {
    fontWeight: 'bold',
    color: 'green',
  },
  value2: {
    marginLeft: 5,
    color: 'green',
    marginVertical: 6,
  },
  field2: {
    fontSize: 8,
    fontFamily: 'NotoSans',
    marginTop: 10,
  },
  detailsContainer2: {
    flexDirection: 'column',
    flexGrow: 1,
    justifyContent: 'space-around',
    marginLeft: 10,
  },
  flag: {
    height: 30,
    width: '20%',
    objectFit: 'contain',
    marginTop: 5,
  },
  newField: {
    fontSize: 8,
    fontFamily: 'NotoSans',
  },
  newlabel: {
    fontWeight: 'bold',
    color: 'yellow',
  },
  headerContent: {
    flexDirection: 'column',
    alignItems: 'center',
  },
  headerSeparator: {
    height: 2,
    width: '100%',
    backgroundColor: 'green',
    position: 'absolute',
    top: 43,
  },
  headerSeparatorYellow: {
    height: 2,
    width: '100%',
    backgroundColor: 'yellow',
    position: 'absolute',
    top: 40,
  },
  memberCard: {
    padding: 2,
    backgroundColor: 'green',
    position: 'absolute',
    top: 35,
    borderRadius: 10,
    left: 70,
  },
  headerImg: {
    height: 70,
    width: '100%',
    objectFit: 'contain',
  },
  header2: {
    marginTop: 10, // Add margin to push it down
    alignItems: 'center', // Center the image
  },
});

const ProfileDocument = ({ data }) => (
  <Document>
    <Page size={[240, 150]} style={styles.page}>
      <View>
        <View style={styles.profileContainer}>
          <View style={styles.header}>
            <View style={styles.headerContent}>
              <Image src={logo} style={styles.logo} />
              <Image src={quote} style={styles.quote} />
              <Image src={address} style={styles.address} />
            </View>
            <Image src={flag} style={styles.flag} />
          </View>
          <View style={styles.headerSeparator} />
          <View style={styles.headerSeparatorYellow} />
          <View style={styles.memberCard}>
            <Text style={styles.newField}>
              <Text style={styles.newlabel}>உறுப்பினர் அட்டை</Text>
            </Text>
          </View>

          <View style={{ flexDirection: 'row', padding: 5 }}>
            <View style={styles.imageView}>
              {data.profilePicture && (
                <Image src={data.originalImage} style={styles.profileImage} />
              )}
            </View>
            <View style={styles.detailsContainer}>
              <Text style={styles.field}>
                <Text style={styles.label}>பெயர்:</Text>
                <Text style={styles.value}> {data.name}</Text>
              </Text>
              <Text style={styles.field}>
                <Text style={styles.label}>உறுப்பினர் எண்:</Text>
                <Text style={styles.value}> {data.memberId}</Text>
              </Text>
              <Text style={styles.field}>
                <Text style={styles.label}>த/பெ - க/பெ:</Text>
                <Text style={styles.value}> {data.fatherOrHusbandName}</Text>
              </Text>
              <Text style={styles.field}>
                <Text style={styles.label}>பிறந்த தேதி:</Text>
                <Text style={styles.value}> {data.birthDate}</Text>
              </Text>
              <Text style={styles.field}>
                <Text style={styles.label}>கல்வித் தகுதி:</Text>
                <Text style={styles.value}> {data.education}</Text>
              </Text>
              <Text style={styles.field}>
                <Text style={styles.label}>தொடர்பு எண்:</Text>
                <Text style={styles.value}> {data.contactNumber}</Text>
              </Text>
              <Text style={styles.field}>
                <Text style={styles.label}>தொழில்:</Text>
                <Text style={styles.value}> {data.contactNumber}</Text>
              </Text>
            </View>
          </View>
        </View>
      </View>
      <View>
        <View style={styles.profileContainer}>
          <View style={styles.header2}>
            <Image src={HeaderImg} style={styles.headerImg} />
          </View>

          <View style={{ flexDirection: 'row', padding: 5 }}>
            <View style={styles.detailsContainer2}>
              <Text style={styles.field2}>
                <Text style={styles.label2}>வாக்காளர் எண்:</Text>
                <Text style={styles.value2}> {data.voterId}</Text>
              </Text>
              <Text style={styles.field2}>
                <Text style={styles.label2}>இரத்தக் குழு:</Text>
                <Text style={styles.value2}> {data.bloodGroup}</Text>
              </Text>
              <Text style={styles.field2}>
                <Text style={styles.label2}>வார்டு எண்:</Text>
                <Text style={styles.value2}> {data.wardNumber}</Text>
              </Text>
              <Text style={styles.field2}>
                <Text style={styles.label2}>சட்டமன்றத் தொகுதி:</Text>
                <Text style={styles.value2}> {data.Assemblyconstituency}</Text>
              </Text>
              <Text style={styles.field2}>
                <Text style={styles.label2}>பாராளுமன்றத் தொகுதி:</Text>
                <Text style={styles.value2}> {data.ParliamentaryConstituency}</Text>
              </Text>
               <Text style={styles.field2}>
                <Text style={styles.label2}>முகவரி:</Text>
                <Text style={styles.value2}> {data.address}</Text>
              </Text>
            </View>
          </View>
        </View>
      </View>
    </Page>
  </Document>
);

export const generatePdf = async (data) => {
  try {
    // Create a PDF document
    const blob = await pdf(<ProfileDocument data={data} />).toBlob();
    // Save the PDF file
    saveAs(blob, 'profile-information.pdf');
  } catch (error) {
    console.error('Error generating PDF:', error);
  }
};

export default ProfileDocument;
