import React from 'react';
import Header from '../components/Header/Header';
import ProfileForm from '../components/Header/ProfileForm';
import Footer from '../components/Header/Footer';
import './welcome.css';

const Welcome = () => {
    return (
        <div className="welcome-container">
            <Header />
            <div>
                <h3 style={{textAlign:'center'}}>உறுப்பினர் சேர்க்கை</h3>
            </div>
            <div className="welcome-content">
                <div className="youtube-video">
                    <iframe
                        src="https://www.youtube.com/embed/0BPUq0D6rJg?si=avCTK9J-PhXQzO4d"
                        title="YouTube video player"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                    ></iframe>
                </div>
                <ProfileForm />
            </div>
          
            <Footer />
        </div>
    );
};

export default Welcome;
