import React, { useState, useEffect } from 'react';
import { generatePdf } from './PDFGenerator';
import { database } from '../../firebase/firebase';
import { ref, set, get } from "firebase/database";
import './ProfileForm.css';
import { getStorage, ref as storageRef, uploadBytes, getDownloadURL } from "firebase/storage";

const ProfileForm = () => {
    const [formData, setFormData] = useState({
        profilePicture: null,
        name: '',
        memberId: '',
        fatherOrHusbandName: '',
        birthDate: '',
        contactNumber: '',
        voterId: '',
        bloodGroup: '',
        address: '',
        originalImage:null,
        wardNumber:'',
        Assemblyconstituency:'',
        ParliamentaryConstituency:'',
        education:'',
        occupation:'',
        
    });

    const [imagePreview, setImagePreview] = useState('');
    const [errors, setErrors] = useState({});

    // Clean up object URLs when component unmounts
    useEffect(() => {
        return () => {
            if (imagePreview) {
                URL.revokeObjectURL(imagePreview);
            }
        };
    }, [imagePreview]);

    const uploadProfilePicture = async (file) => {
        try {
            const storage = getStorage();
            const storageReference = storageRef(storage, `profilePictures/${file.name}`);
            await uploadBytes(storageReference, file);
            const downloadURL = await getDownloadURL(storageReference);
            return downloadURL;
        } catch (error) {
            console.error('Error uploading file:', error);
            throw new Error('Error uploading file.');
        }
    };

    const Push = async () => {
        try {
            const memberRef = ref(database, `users/${formData.memberId}`);
            const snapshot = await get(memberRef);

            if (snapshot.exists()) {
                alert('Member ID already exists. Please use a different Member ID.');
                return false;
            } else {
                await set(memberRef, {
                    name: formData.name,
                    memberId:formData.memberId,
                    profilePicture: formData.profilePicture,
                    contactNumber: formData.contactNumber,
                    fatherOrHusbandName: formData.fatherOrHusbandName,
                    birthDate: formData.birthDate,
                    voterId: formData.voterId,
                    bloodGroup: formData.bloodGroup,
                    address: formData.address,
                    wardNumber:formData.wardNumber,
                    ParliamentaryConstituency:formData.ParliamentaryConstituency,
                    education:formData.education,
                    occupation:formData.occupation,
                });
                alert('Data saved successfully');
                return true;
            }
        } catch (error) {
            console.error('Error saving data:', error);
            alert('Error saving data. Please try again.');
            return false;
        }
    };

    const handleChange = (e) => {
        const { name, value, files } = e.target;
        if (name === 'profilePicture' && files) {
            const file = files[0];
            if (file && file instanceof File) {
                if (file.size > 2 * 1024 * 1024) { // Limit to 2 MB
                    setErrors(prevErrors => ({
                        ...prevErrors,
                        profilePicture: 'File size exceeds 2 MB'
                    }));
                    return;
                }
                if (!file.type.startsWith('image/')) {
                    setErrors(prevErrors => ({
                        ...prevErrors,
                        profilePicture: 'Selected file is not an image'
                    }));
                    return;
                }
                const previewUrl = URL.createObjectURL(file);
                setImagePreview(previewUrl); // Create image preview
                setFormData(prevFormData => ({
                    ...prevFormData,
                    profilePicture: file,
                    originalImage:file
                }))
            } else {
                console.error('Selected file is not valid.');
            }
        } else {
            setFormData(prevFormData => ({
                ...prevFormData,
                [name]: value
            }));
        }
    };

    const validate = () => {
        const errors = {};
        if (!formData.profilePicture) errors.profilePicture = 'சுயவிவரப் படம் தேவை';
        if (!formData.name) errors.name = 'பெயர் தேவை';
        if (!formData.memberId) errors.memberId = 'உறுப்பினர் எண் தேவை';
        if (!formData.fatherOrHusbandName) errors.fatherOrHusbandName = 'தந்தை அல்லது கணவர் பெயர் தேவை';
        if (!formData.birthDate) errors.birthDate = 'பிறந்த தேதி தேவை';
        if (!formData.contactNumber) errors.contactNumber = 'தொடர்பு எண் தேவை';
        if (!formData.voterId) errors.voterId = 'வரிசை எண் தேவை';
        if (!formData.bloodGroup) errors.bloodGroup = 'இரத்தக் குழு தேவை';
        if (!formData.address) errors.address = 'முகவரி தேவை';
        if (!formData.wardNumber) errors.wardNumber = 'வார்டு எண்';
        if (!formData.Assemblyconstituency) errors.Assemblyconstituency = 'சட்டமன்றத் தொகுதி';
        if (!formData.ParliamentaryConstituency) errors.ParliamentaryConstituency = 'பாராளுமன்றத் தொகுதி'
          if (!formData.education) errors.education = 'கல்வித் தகுதி';
          if (!formData.occupation) errors.occupation = 'தொழில்';

        return errors;
    };

    const handleSubmit = async (e) => {
        console.log(formData,"formdata")
        e.preventDefault();
        const validationErrors = validate();
        setErrors(validationErrors);

        if (Object.keys(validationErrors).length === 0) {
            try {
                let profilePictureUrl = '';
                if (formData.profilePicture) {
                    profilePictureUrl = await uploadProfilePicture(formData.profilePicture);
                }
                formData.profilePicture = profilePictureUrl;
                const isPushSuccessful = await Push();
                if (isPushSuccessful) {
                    await generatePdf(formData);
                }
            } catch (error) {
                console.error('Error during form submission:', error);
            }
        }
    };

    return (
        <form className="profile-form" onSubmit={handleSubmit}>
            <div className="form-group">
                <label htmlFor="profilePicture">சுயவிவரப் படம்</label>
                <div className="image-placeholder">
                    {imagePreview ? (
                        <img
                            src={imagePreview}
                            alt="Profile Preview"
                            className="image-preview"
                        />
                    ) : (
                        <span className="image-placeholder-text">சுயவிவரப் படம்</span>
                    )}
                    <input
                        type="file"
                        id="profilePicture"
                        name="profilePicture"
                        accept="image/*"
                        onChange={handleChange}
                        className="file-input"
                    />
                </div>
                {errors.profilePicture && <span className="error">{errors.profilePicture}</span>}
            </div>
            <div className="form-group">
                <label htmlFor="name">பெயர்</label>
                <input type="text" id="name" name="name" value={formData.name} onChange={handleChange} />
                {errors.name && <span className="error">{errors.name}</span>}
            </div>
            <div className="form-group">
                <label htmlFor="memberId">உறுப்பினர் எண்</label>
                <input type="text" id="memberId" name="memberId" value={formData.memberId} onChange={handleChange} />
                {errors.memberId && <span className="error">{errors.memberId}</span>}
            </div>
            <div className="form-group">
                <label htmlFor="fatherOrHusbandName">தந்தை அல்லது கணவர் பெயர்</label>
                <input type="text" id="fatherOrHusbandName" name="fatherOrHusbandName" value={formData.fatherOrHusbandName} onChange={handleChange} />
                {errors.fatherOrHusbandName && <span className="error">{errors.fatherOrHusbandName}</span>}
            </div>
            <div className="form-group">
                <label htmlFor="birthDate">பிறந்த தேதி</label>
                <input type="date" id="birthDate" name="birthDate" value={formData.birthDate} onChange={handleChange} />
                {errors.birthDate && <span className="error">{errors.birthDate}</span>}
            </div>
            <div className="form-group">
                <label htmlFor="contactNumber">தொடர்பு எண்</label>
                <input type="text" id="contactNumber" name="contactNumber" value={formData.contactNumber} onChange={handleChange} />
                {errors.contactNumber && <span className="error">{errors.contactNumber}</span>}
            </div>
            <div className="form-group">
                <label htmlFor="voterId">வாக்காளர் எண்</label>
                <input type="text" id="voterId" name="voterId" value={formData.voterId} onChange={handleChange} />
                {errors.voterId && <span className="error">{errors.voterId}</span>}
            </div>
            <div className="form-group">
                <label htmlFor="bloodGroup">இரத்தக் குழு</label>
                <input type="text" id="bloodGroup" name="bloodGroup" value={formData.bloodGroup} onChange={handleChange} />
                {errors.bloodGroup && <span className="error">{errors.bloodGroup}</span>}
            </div>
            <div className="form-group">
                <label htmlFor="address">முகவரி</label>
                <input type="text" id="address" name="address" value={formData.address} onChange={handleChange} />
                {errors.address && <span className="error">{errors.address}</span>}
            </div>
            <div className="form-group">
                <label htmlFor="address"> வார்டு எண் </label>
                <input type="text" id="wardNumber" name="wardNumber" value={formData.wardNumber} onChange={handleChange} />
                {errors.address && <span className="error">{errors.wardNumber}</span>}
            </div>
            <div className="form-group">
                <label htmlFor="Assemblyconstituency"> சட்டமன்றத் தொகுதி </label>
                <input type="text" id="Assemblyconstituency" name="Assemblyconstituency" value={formData.Assemblyconstituency} onChange={handleChange} />
                {errors.Assemblyconstituency && <span className="error">{errors.Assemblyconstituency}</span>}
            </div>
            <div className="form-group">
                <label htmlFor="ParliamentaryConstituency">பாராளுமன்றத் தொகுதி </label>
                <input type="text" id="ParliamentaryConstituency" name="ParliamentaryConstituency" value={formData.ParliamentaryConstituency} onChange={handleChange} />
                {errors.ParliamentaryConstituency && <span className="error">{errors.ParliamentaryConstituency}</span>}
            </div>
            <div className="form-group">
                <label htmlFor="education">கல்வித் தகுதி</label>
                <input type="text" id="education" name="education" value={formData.education} onChange={handleChange} />
                {errors.education && <span className="error">{errors.education}</span>}
            </div>
            <div className="form-group">
                <label htmlFor="occupation">தொழில்</label>
                <input type="text" id="occupation" name="occupation" value={formData.occupation} onChange={handleChange} />
                {errors.occupation && <span className="error">{errors.occupation}</span>}
            </div>
           
           
            <div className="form-group">
                <button type="submit">பதிவேற்றவும்</button>
            </div>
        </form>
    );
};

export default ProfileForm;
